<template>
  <div class="wrap">
    <div class="wrap-header">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/declaration' }">Pre-declaration </el-breadcrumb-item>
        <el-breadcrumb-item>{{ title }} Pre-declaration</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wrap-content">
      <div class="basic_info">
        <el-form ref="form" :model="basicInfo" :label-position="labelPosition" label-width="150px">
          <el-row :gutter="20" justify="end">
            <el-col :span="12" class="empty-box"></el-col>
            <el-col :span="12">
              <el-form-item label="Created By">{{ username }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Cargo Type" required>
                <el-select v-model="basicInfo.cargoType" placeholder="choose..." :disabled="disabled">
                  <el-option
                    v-for="item in cargoTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Truck No." required>
                <el-input v-model="basicInfo.truckNo" placeholder="e.g. UA1234" :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Date">
                <el-date-picker
                  v-model="basicInfo.date"
                  type="date"
                  :disabled="disabled"
                  placeholder="Select date"
                  value-format="yyyy-MM-dd"
                  :editable="false">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Trucker Name" required>
                <el-select
                  v-model="basicInfo.truckerName"
                  filterable
                  remote
                  allow-create
                  reserve-keyword
                  default-first-option
                  placeholder="e.g. CHUI KAM WO"
                  :remote-method="remoteMethodTruckerName"
                  suffix-icon="el-icon-search"
                  :automatic-dropdown="true"
                  :disabled="disabled"
                  :loading-text="loadingText"
                  :loading="truckerNameLoading"
                  class="destinationSelect"
                  @change="truckerNameChange($event)">
                  <el-option
                    v-for="item in truckerNameOptions"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Pallet No." :required="basicInfo.cargoType!=='BUK'?true:false">
                <el-input v-model="basicInfo.uld" placeholder="Input ULD#" :disabled="disabled" style="width:44%"></el-input> /
                <el-input v-model="basicInfo.contour" placeholder="Input contour" :disabled="disabled" style="width:44%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="HKID No.">
                <el-input v-model="basicInfo.hkidNo" placeholder="e.g. K1234567" :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
             <el-col :span="12">
              <el-form-item label="Destination" required>
                <el-select
                  v-model="basicInfo.destination"
                  filterable
                  remote
                  reserve-keyword
                  default-first-option
                  placeholder="e.g. PVG"
                  :remote-method="remoteMethodDestination"
                  suffix-icon="el-icon-search"
                  :disabled="disabled"
                  :loading="destinationLoading"
                  :loading-text="loadingText"
                  class="destinationSelect">
                  <el-option
                    v-for="item in destinationOptions"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Seal No." required class="select-input">
                <el-select
                  v-model="basicInfo.sealNo"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  :disabled="disabled"
                  :popper-append-to-body="false"
                  :automatic-dropdown="false"
                  placeholder="Press Enter to finish each Seal No. Input.">
                  <div slot="empty"></div>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Flight No. & Date" required>
                <el-input v-model="basicInfo.flightNo" placeholder="e.g. CX1009" :disabled="disabled" style="width:44%" @input="LimitInput(basicInfo.flightNo, 'string')"></el-input> /
                <el-date-picker
                  v-model="basicInfo.flightDate"
                  type="date"
                  :disabled="disabled"
                  style="width:44%"
                  placeholder="Select date"
                  :editable="false"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Number Of Seal" required>
                <el-input v-model="basicInfo.numberOfseal" placeholder="e.g. 19" :disabled="disabled" @input="LimitInput(basicInfo.numberOfseal,'uint')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="list_info">
        <el-table :data="listInfo.infoData" class="list_info_table" border>
          <el-table-column
            prop="awbNo"
            align="center">
            <template slot="header">
              <span class="title"><span class="required">*</span>AWB#</span>
            </template>
            <template slot-scope="scope">
              <div class="awb-style">
                <i class="el-icon-circle-plus-outline add" v-if="title!=='View'&&scope.$index===0" @click="addInfoDataItem()"></i>
                <i class="el-icon-remove-outline add" v-if="title!=='View'&&scope.$index!==0" @click="delectInfoDataItem(scope.$index)"></i>
                <el-select
                  v-model="scope.row.awbNo"
                  filterable
                  remote
                  allow-create
                  reserve-keyword
                  collapse-tags
                  default-first-option
                  placeholder="e.g. 112-11004895"
                  :remote-method="remoteMethodAwb"
                  :loading="awbLoading"
                  :loading-text="loadingText"
                  suffix-icon="el-icon-search"
                  :disabled="disabled"
                  :ref="'searchSelect'+scope.$index"
                  @change="awbChange(scope.$index)"
                  @focus="selectAwbFocus($event, scope.$index)">
                  <el-option
                    v-for="item in awbOptions"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="destination"
            align="center">
            <template slot="header">
              <span class="title"><span class="required">*</span>Destination</span>
            </template>
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.dest"
                filterable
                remote
                reserve-keyword
                default-first-option
                placeholder="e.g. PVG"
                :remote-method="remoteMethodListDestination"
                suffix-icon="el-icon-search"
                :disabled="scope.row.destDisabled"
                :loading-text="loadingText"
                :loading="destinationLoading"
                class="destinationSelect">
                <el-option
                  v-for="item in destinationOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="pieces"
            align="center">
            <template slot="header">
              <span class="title"><span class="required">*</span>Pieces</span>
            </template>
            <template slot-scope="scope">
              <el-input v-model="scope.row.pieces" :disabled="disabled" @keyup.native="piecesCheck(scope.row.pieces, scope.$index)"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="weight"
            align="center">
            <template slot="header">
              <span class="title"><span class="required">*</span>Weight(KG)</span>
            </template>
            <template slot-scope="scope">
              <el-input v-model="scope.row.weight" :disabled="disabled" @keyup.native="weightCheck(scope.row.weight, scope.$index)"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-row type="flex" class="row-bg marginTop" justify="end">
          <el-col :span="6" class="right">Total: {{ piecesTotal.toFixed(0) === 'NaN' ? 0 : piecesTotal.toFixed(0) }}</el-col>
          <el-col :span="6" class="right">Total: {{ weightTotal.toFixed(0) === 'NaN' ? 0 : (typeof weightTotal === 'number' && weightTotal % 1 === 0 ? weightTotal.toFixed(0) : weightTotal.toFixed(1)) }}</el-col>
        </el-row>
      </div>
      <!-- print template-->
      <div id="printTemplate">
        <div class="print-header" style="display:flex;font-size:12px;">
          <img src="@/assets/index/templateLogo.png" style="width:170px;height:70px;">
          <div>
            <span style="display:block;padding:5px 0;font-weight:900;">U-Freight Limited</span>
            <span style="display:block;padding-bottom:5px;border-bottom: 1px solid #000;">21st Floor, Enterprise Square III, 39Wang Chiu Road, Kowloon Bay, Kowloon, Hong Kong.</span>
            <span style="display:block;padding-top:5px;">Tel No. 2362 2201&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fax No. 2764 1574 / 2764 2473&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Internet URL : http:// www. ufreight.com </span>
          </div>
        </div>
        <div class="print-body">
          <el-form ref="form" :model="basicInfo" :label-position="labelPosition" label-width="150px">
            <el-form-item label="RE:" style="margin-bottom: 0px;">
              <span>{{ judgeCargoType(basicInfo.cargoType) }}</span>
            </el-form-item>
            <el-form-item label="From:" style="margin-bottom: 0px;">{{basicInfo.from}}</el-form-item>
            <el-form-item label="Date:" style="margin-bottom: 0px;">{{getDMY(basicInfo.date)}}</el-form-item>
            <el-form-item label="Pallet no.:" style="margin-bottom: 0px;">
              <span v-if="basicInfo.cargoType!=='BUK'">{{basicInfo.uld ? basicInfo.uld.toUpperCase() : ''}} / {{basicInfo.contour ? basicInfo.contour.toUpperCase() : ''}}</span>
              <span v-else>NA</span>
            </el-form-item>
            <el-form-item label="Destination:" style="margin-bottom: 0px;">{{basicInfo.destination}}</el-form-item>
            <el-form-item label="Flight no.:" style="margin-bottom: 0px;">
              {{basicInfo.flightNo ? basicInfo.flightNo.toUpperCase() : ''}} / {{getDM(basicInfo.flightDate)}}
            </el-form-item>
          </el-form>
          <span v-if="basicInfo.cargoType!=='BUK'">** The following consignment are loaded inside prepacked unit**</span>
          <table border="1" :cellspacing="0" style="border:1px solid #000;">
            <tr>
              <th style="padding:10px;width:155px;">Airwaybill No.</th>
              <th style="padding:10px;width:155px;">Destination</th>
              <th style="padding:10px;width:155px;">Pieces</th>
              <th style="padding:10px;width:155px;">Weight (kg)</th>
            </tr>
            <tr v-for="(i,index) in listInfo.infoData" :key="index">
              <td style="padding:4px;width:150px;text-align:center;">{{i.awbNo}}</td>
              <td style="padding:4px;width:150px;text-align:center;">{{i.dest}}</td>
              <td style="padding:4px;width:150px;text-align:center;">{{i.pieces}}</td>
              <td style="padding:4px;width:150px;text-align:center;">{{i.weight}}</td>
            </tr>
            <tr v-for="(i,index) in emptyLength" :key="index+'-'+index">
              <td style="padding:10px;width:150px;text-align:center;">{{i.awbNo}}</td>
              <td style="padding:10px;width:150px;text-align:center;">{{i.dest}}</td>
              <td style="padding:10px;width:150px;text-align:center;">{{i.pieces}}</td>
              <td style="padding:10px;width:150px;text-align:center;">{{i.weight}}</td>
            </tr>
            <tr>
              <td style="padding:4px;width:150px;text-align:center;">Total</td>
              <td style="padding:4px;width:150px;text-align:center;"></td>
              <td style="padding:4px;width:150px;text-align:center;">{{ piecesTotal.toFixed(0) === 'NaN' ? 0 : piecesTotal.toFixed(0) }}</td>
              <td style="padding:4px;width:150px;text-align:center;">{{ weightTotal.toFixed(0) === 'NaN' ? 0 : (typeof weightTotal === 'number' && weightTotal % 1 === 0 ? weightTotal.toFixed(0) : weightTotal.toFixed(1)) }}</td>
            </tr>
          </table>
          <div style="display:flex;justify-content: space-between;position: fixed;bottom:90px;">
            <el-form ref="form" :model="basicInfo" :label-position="labelPosition" label-width="150px">
              <el-form-item label="Seal No.:" style="margin-bottom: 0px;">
                <span v-for="(i,index) in basicInfo.sealNo" :key="index">
                  {{basicInfo.sealNo.length-1 === index ? i.toUpperCase() : i.toUpperCase()+','}}
                </span>
              </el-form-item>
              <el-form-item label="Number of Seal:" style="margin-bottom: 0px;">
                {{basicInfo.numberOfseal}}
              </el-form-item>
              <el-form-item label="Truck No.:" style="margin-bottom: 0px;">
                {{basicInfo.truckNo ? basicInfo.truckNo.toUpperCase() : ''}}
              </el-form-item>
              <el-form-item label="Trucker Name:" style="margin-bottom: 0px;">
                {{basicInfo.truckerName ? basicInfo.truckerName.toUpperCase() : ''}}
              </el-form-item>
              <!-- <el-form-item label="HKID No.:" style="margin-bottom: 0px;">
                {{basicInfo.hkidNo ? basicInfo.hkidNo.toUpperCase() : ''}}
              </el-form-item> -->
            </el-form>
            <div style="width:230px;font-size:12px;margin-left:130px;">
              <img src="@/assets/index/templateLogo1.png" style="width:230px;height:130px;">
              <span style="display:block;padding-bottom:5px;border-bottom: 1px solid #000;"> U-Freight Limited    ( RA00034 )  </span>
              <span style="display:block;padding-top:15px;"> Agent Signature & Company Chop </span>
            </div>
          </div>
          <div style="font-size:12px;text-align:center;position: fixed;bottom:24px;left:80px;">
            <span>All transactions are subject to our Company Trading Terms and Conditions which are available upon request. </span>
          </div>
        </div>
      </div>
      <div class="submit-btn">
        <el-button v-if="title!=='Create' && title!=='Update'" type="primary" @click="submit()" :disabled="disabledDownloadRCL!==true">Submit to HKIA</el-button>
        <el-button v-if="title!=='Create' && title!=='Update'" type="primary" @click="toUpdate()">Update</el-button>
        <el-button v-if="title!=='Create' && title!=='Update'" type="primary" v-print="printObj" >Generate Report</el-button>
        <el-button v-if="title!=='Create' && title!=='Update'" type="primary" @click="downloadRCL" :disabled="disabledDownloadRCL">Download RCL</el-button>
        <el-button v-if="title!=='Create' && title !== 'View'" type="primary" @click="update()">Save</el-button>
        <el-button v-if="title==='Create'" type="primary" @click="save()">Save</el-button>
        <el-button v-if="title==='Create' || title==='Update'" plain @click="cancel()">Cancel</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getFasaData, judgeAwbExist } from '@/api/afsa-data/afsa-api.js'
import { getTruckerName, createPreDeclaration, queryPreDeclarationById, updatePreDeclaration, queryDeclaration, submitPreDeclaration, exportOrderPreDeclaration, downloadFileRCL } from '@/api/declaration/declaration-api.js'
import { getOfficeCode, getName } from '@/utils/auth'

export default {
  name: 'create',
  data () {
    return {
      printObj: {
        id: 'printTemplate', // 这里是要打印元素的ID
        popTitle: '', // 打印的标题
        extraCss: '../../assets/styles/print.css', // 打印可引入外部的一个 css 文件
        extraHead: '' // 打印头部文字
      },
      basicInfo: {
        cargoType: 'BUK',
        from: 'U-Freight Limited',
        date: this.getYMD(new Date()),
        uld: null,
        contour: null,
        destination: null,
        flightNo: null,
        flightDate: null,
        truckNo: null,
        sealNo: [],
        numberOfseal: null,
        truckerName: null,
        hkidNo: null
      },
      listInfo: {
        infoData: [
          {
            awbNo: null,
            dest: null,
            pieces: null,
            weight: null,
            destDisabled: true
          }
        ]
      },
      labelPosition: 'right',
      cargoTypeOptions: [
        {
          value: 'BUK',
          label: 'BULK SHIPMENT'
        },
        {
          value: 'MIX',
          label: 'MIX-LOAD SHIPMENT'
        },
        {
          value: 'PPK',
          label: 'PRE-PACK SHIPMENT'
        }
      ],
      title: this.$route.query.type,
      disabled: false,
      loadingText: 'Loading...',
      destinationLoading: false,
      destinationOptions: [],
      awbLoading: false,
      awbOptions: [],
      truckerNameOptions: [],
      truckerNameLoading: false,
      username: getName(),
      alreadyExistsList: [], // 保存list的awb
      preDeclarationId: '', // preDeclarationId
      disabledDownloadRCL: true // DownloadRCL按钮禁用
    }
  },
  mounted () {
    this.initView()
  },
  computed: {
    piecesTotal () {
      return this.listInfo.infoData.map(row => row.pieces).reduce((acc, cur) => (parseFloat((cur === null ? 0 : cur)) + acc), 0)
    },
    weightTotal () {
      return this.listInfo.infoData.map(row => row.weight).reduce((acc, cur) => (parseFloat((cur === null ? 0 : cur)) + acc), 0)
    },
    emptyLength () {
      if (12 - this.listInfo.infoData.length > 0) return 12 - this.listInfo.infoData.length
      else return 0
    },
    judgeCargoType () {
      return val => {
        if (val === 'BUK') return 'BULK SHIPMENT'
        if (val === 'MIX') return 'MIX-LOAD SHIPMENT'
        if (val === 'PPK') return 'PRE-PACK SHIPMENT'
      }
    }
  },
  methods: {
    initView () {
      if ((this.title === 'View' || this.title === 'Update') && this.$route.query.preDeclarationId) {
        queryPreDeclarationById(this.$route.query.preDeclarationId).then(res => {
          if (res.data.CODE === 200) {
            const result = res.data.DATA[0]
            this.listInfo.infoData = []
            const data1 = []
            result.predecData.awbList.forEach(element => {
              data1.push({
                awbNo: element.awbNo,
                dest: element.dest,
                pieces: element.pieces,
                weight: element.weight,
                destDisabled: true
              })
            })
            this.$nextTick(() => {
              this.listInfo.infoData = data1
            })
            if (this.title === 'View') {
              this.disabled = true
              if (result.rclAvailable === 'Yes') {
                this.disabledDownloadRCL = false
              }
            }
            this.basicInfo.cargoType = result.predecData.cargoType
            this.basicInfo.date = result.predecData.date
            this.basicInfo.uld = result.unitLoadDevice
            this.basicInfo.contour = result.predecData.countour
            this.basicInfo.destination = result.predecData.destination
            this.basicInfo.flightNo = result.predecData.flightNo
            this.basicInfo.flightDate = result.predecData.flightDate
            this.basicInfo.truckNo = result.predecData.truckNo
            this.basicInfo.sealNo = result.predecData.sealNoList
            this.basicInfo.numberOfseal = result.predecData.numberOfSeal
            this.basicInfo.truckerName = result.predecData.truckerName
            this.basicInfo.hkidNo = result.predecData.hkidNo
            this.username = result.predecData.createdBy
            this.preDeclarationId = result.preDeclarationId
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    addInfoDataItem () {
      if (this.disabled === false) {
        this.listInfo.infoData.push({
          awbNo: null,
          dest: null,
          pieces: null,
          weight: null,
          destDisabled: true
        })
      }
    },
    delectInfoDataItem (val) {
      if (this.disabled === false) {
        if (this.alreadyExistsList.indexOf(this.listInfo.infoData[val].awbNo) !== -1) {
          let i = this.alreadyExistsList.indexOf(this.listInfo.infoData[val].awbNo)
          this.alreadyExistsList.splice(i, 1)
        }
        this.listInfo.infoData.splice(val, 1)
      }
    },
    submit () {
      let mawbNos = [] // 主单号数组
      let unloadList = [] // 没有load的主单号数组
      let loadArray = [] // 已经load的主单号数组
      let promiseList = []
      this.listInfo.infoData.forEach(element => {
        mawbNos.push(element.awbNo)
      })
      // 访问接口拿到已经load的主单号
      let judge = new Promise(resolve => {
        let judgeAwbExistParams = {
          officeCode: getOfficeCode(),
          mawbNos: mawbNos
        }
        judgeAwbExist(judgeAwbExistParams).then(res => {
          if (res.data.CODE === 200) {
            loadArray = res.data.DATA
            resolve()
          }
        }).catch(err => {
          console.log(err)
        })
      })
      Promise.all([judge]).then(() => {
        // 遍历mawbNos数组拿到没有load的主单号
        mawbNos.forEach(element => {
          let element1 = new Promise((resolve) => {
            if (loadArray.indexOf(element) === -1) {
              unloadList.push(element)
            }
            resolve()
          })
          promiseList.push(element1)
        })
        Promise.all(promiseList).then(() => {
          if (unloadList.length > 0) {
            this.$confirm(`${unloadList.join('、')} not exists, please load data from AFSA first!`, 'Error', {
              confirmButtonText: 'Load AFSA Data',
              showCancelButton: false
            }).then(() => {
              this.$router.push({ path: '/afsa-data/afsa-data-list' })
            }).catch(() => {
              console.log('已取消')
            })
          } else if (loadArray.length > 0) {
            const exportParams = {
              officeCode: getOfficeCode(),
              preDeclarationId: this.$route.query.preDeclarationId,
              unitLoadDevice: this.basicInfo.uld ? this.basicInfo.uld.toUpperCase() : null,
              mawbNos: loadArray
            }
            exportOrderPreDeclaration(exportParams).then(res => {
              if (res.data.CODE === 200) {
                this.submitting()
              } else {
                let errorList = res.data.DATA ? res.data.DATA : []
                this.$confirm(`${errorList.join('、')} send export order failed, pls click 'Check Submit Status' for more detail.`, 'Error', {
                  confirmButtonText: 'To Check Submit Status',
                  showCancelButton: false
                }).then(() => {
                  this.$router.push({ path: '/declaration/check' })
                }).catch(() => {
                  console.log('已取消')
                })
              }
            }).catch(err => {
              console.log(err)
            })
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch((error) => {
        console.log(error)
      })
    },
    submitting () {
      let awbList = []
      let sealNolist = []
      this.listInfo.infoData.forEach(item => {
        awbList.push({
          awbNo: item.awbNo,
          dest: item.dest,
          pieces: item.pieces,
          weight: item.weight
        })
      })
      this.basicInfo.sealNo.forEach(element => {
        sealNolist.push(element.toUpperCase())
      })
      let params = {
        preDeclarationId: this.$route.query.preDeclarationId,
        createdBy: getName(),
        cargoType: this.basicInfo.cargoType,
        date: this.basicInfo.date,
        unitLoadDevice: this.basicInfo.uld ? this.basicInfo.uld.toUpperCase() : null,
        countour: this.basicInfo.contour ? this.basicInfo.contour.toUpperCase() : null,
        destination: this.basicInfo.destination ? this.basicInfo.destination.toUpperCase() : null,
        flightNo: this.basicInfo.flightNo ? this.basicInfo.flightNo.toUpperCase() : null,
        flightDate: this.getYMD(this.basicInfo.flightDate),
        truckNo: this.basicInfo.truckNo ? this.basicInfo.truckNo.toUpperCase() : null,
        truckerName: this.basicInfo.truckerName ? this.basicInfo.truckerName.toUpperCase() : null,
        hkidNo: this.basicInfo.hkidNo ? this.basicInfo.hkidNo.toUpperCase() : null,
        sealNoList: sealNolist,
        numberOfSeal: this.basicInfo.numberOfseal,
        officeCode: getOfficeCode(),
        piecesTotal: this.piecesTotal,
        weightTotal: this.weightTotal,
        awbList: awbList
      }
      submitPreDeclaration(params).then(res => {
        if (res.data.CODE === 200) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Submit export order and pre-declaration to HKIA successfully!',
            type: 'success',
            showClose: true,
            duration: 8000,
            offset: 120
          })
          this.$router.push({ path: '/declaration/declaration-list' })
        } else if (res.data.CODE === 1007) {
          if (res.data.MESSAGE) {
            this.$confirm(`${res.data.MESSAGE}`, 'Error', {
              confirmButtonText: 'To Check Submit Status',
              showCancelButton: false
            }).then(() => {
              this.$router.push({ path: '/declaration/check' })
            }).catch(() => {
              console.log('已取消')
            })
          }
        } else {
          if (res.data.MESSAGE) {
            this.$confirm("Submit pre-declaration to HKIA failed, pls click 'Check Submit Status' for more detail.", 'Error', {
              confirmButtonText: 'To Check Submit Status',
              showCancelButton: false
            }).then(() => {
              this.$router.push({ path: '/declaration/check' })
            }).catch(() => {
              console.log('已取消')
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    toUpdate () {
      this.$router.push({ query: { type: 'Update', preDeclarationId: this.$route.query.preDeclarationId } })
      this.disabled = false
      this.title = 'Update'
    },
    update () {
      if (this.$route.query.preDeclarationId) {
        if (this.basicInfo.truckNo === null || this.basicInfo.truckNo === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Truck No. cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return false
        } else if (this.basicInfo.truckerName === null || this.basicInfo.truckerName === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Trucker Name cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return false
        } else if (this.basicInfo.cargoType !== 'BUK' && (this.basicInfo.uld === null || this.basicInfo.uld === '' || this.basicInfo.contour === null || this.basicInfo.contour === '')) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Pallet No cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
        } else if (this.basicInfo.destination === null || this.basicInfo.destination === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Destination cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return false
        } else if (this.basicInfo.sealNo.length === 0) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Seal No. cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return false
        } else if (this.basicInfo.flightNo === null || this.basicInfo.flightNo === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Flight No. cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return false
        } else if (this.basicInfo.flightDate === null || this.basicInfo.flightDate === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Flight Date. cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return false
        } else if (this.basicInfo.numberOfseal === null || this.basicInfo.numberOfseal === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Number Of Seal cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return false
        } else {
          let list = this.IsNULLInfoData(this.listInfo.infoData)
          if (list.length === 0) return
          let sealNolist = []
          this.basicInfo.sealNo.forEach(element => {
            sealNolist.push(element.toUpperCase())
          })
          const params = {
            id: this.$route.query.preDeclarationId,
            data: {
              createdBy: this.username,
              updatedBy: getName(),
              cargoType: this.basicInfo.cargoType,
              date: this.basicInfo.date,
              unitLoadDevice: this.basicInfo.uld ? this.basicInfo.uld.toUpperCase() : null,
              countour: this.basicInfo.contour ? this.basicInfo.contour.toUpperCase() : null,
              destination: this.basicInfo.destination.toUpperCase(),
              flightNo: this.basicInfo.flightNo.toUpperCase(),
              flightDate: this.getYMD(this.basicInfo.flightDate),
              truckNo: this.basicInfo.truckNo.toUpperCase(),
              truckerName: this.basicInfo.truckerName.toUpperCase(),
              hkidNo: this.basicInfo.hkidNo ? this.basicInfo.hkidNo.toUpperCase() : null,
              sealNoList: sealNolist,
              numberOfSeal: this.basicInfo.numberOfseal,
              officeCode: getOfficeCode(),
              piecesTotal: this.piecesTotal,
              weightTotal: (typeof this.weightTotal === 'number' && this.weightTotal % 1 === 0) ? this.weightTotal.toFixed(0) : this.weightTotal.toFixed(1),
              awbList: list
            }
          }
          updatePreDeclaration(params).then(res => {
            if (res.data.CODE === 200) {
              this.$message({
                dangerouslyUseHTMLString: true,
                message: `pre-declaration ${this.$route.query.preDeclarationId} update successful!`,
                type: 'success',
                showClose: true,
                duration: 3000,
                offset: 120
              })
              this.$router.push({ query: { type: 'View', preDeclarationId: this.$route.query.preDeclarationId } })
              this.title = 'View'
              this.disabled = true
              this.listInfo.infoData = list
              this.listInfo.infoData.forEach(element => {
                this.$set(element, 'destDisabled', true)
              })
            } else {
              if (res.data.MESSAGE) {
                this.$message({
                  dangerouslyUseHTMLString: true,
                  message: `${res.data.MESSAGE}`,
                  type: 'error',
                  showClose: true,
                  duration: 8000,
                  offset: 120
                })
              }
            }
          }).catch(err => {
            console.log(err)
          })
        }
      }
    },
    save () {
      if (this.basicInfo.truckNo === null || this.basicInfo.truckNo === '') {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: 'Truck No. cannot be empty!',
          type: 'error',
          showClose: true,
          duration: 3000,
          offset: 120
        })
        return false
      } else if (this.basicInfo.truckerName === null || this.basicInfo.truckerName === '') {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: 'Trucker Name cannot be empty!',
          type: 'error',
          showClose: true,
          duration: 3000,
          offset: 120
        })
        return false
      } else if (this.basicInfo.cargoType !== 'BUK' && (this.basicInfo.uld === null || this.basicInfo.uld === '' || this.basicInfo.contour === null || this.basicInfo.contour === '')) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: 'Pallet No cannot be empty!',
          type: 'error',
          showClose: true,
          duration: 3000,
          offset: 120
        })
      } else if (this.basicInfo.destination === null || this.basicInfo.destination === '') {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: 'Destination cannot be empty!',
          type: 'error',
          showClose: true,
          duration: 3000,
          offset: 120
        })
        return false
      } else if (this.basicInfo.sealNo.length === 0) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: 'Seal No. cannot be empty!',
          type: 'error',
          showClose: true,
          duration: 3000,
          offset: 120
        })
        return false
      } else if (this.basicInfo.flightNo === null || this.basicInfo.flightNo === '') {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: 'Flight No. cannot be empty!',
          type: 'error',
          showClose: true,
          duration: 3000,
          offset: 120
        })
        return false
      } else if (this.basicInfo.flightDate === null || this.basicInfo.flightDate === '') {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: 'Flight Date. cannot be empty!',
          type: 'error',
          showClose: true,
          duration: 3000,
          offset: 120
        })
        return false
      } else if (this.basicInfo.numberOfseal === null || this.basicInfo.numberOfseal === '') {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: 'Number Of Seal cannot be empty!',
          type: 'error',
          showClose: true,
          duration: 3000,
          offset: 120
        })
        return false
      } else {
        let list = this.IsNULLInfoData(this.listInfo.infoData)
        if (list.length === 0) return
        let sealNolist = []
        this.basicInfo.sealNo.forEach(element => {
          sealNolist.push(element.toUpperCase())
        })
        const params = {
          createdBy: getName(),
          cargoType: this.basicInfo.cargoType,
          date: this.basicInfo.date,
          unitLoadDevice: this.basicInfo.uld ? this.basicInfo.uld.toUpperCase() : null,
          countour: this.basicInfo.contour ? this.basicInfo.contour.toUpperCase() : null,
          destination: this.basicInfo.destination.toUpperCase(),
          flightNo: this.basicInfo.flightNo.toUpperCase(),
          flightDate: this.getYMD(this.basicInfo.flightDate),
          truckNo: this.basicInfo.truckNo.toUpperCase(),
          truckerName: this.basicInfo.truckerName.toUpperCase(),
          hkidNo: this.basicInfo.hkidNo ? this.basicInfo.hkidNo.toUpperCase() : null,
          sealNoList: sealNolist,
          numberOfSeal: this.basicInfo.numberOfseal,
          officeCode: getOfficeCode(),
          piecesTotal: this.piecesTotal,
          weightTotal: (typeof this.weightTotal === 'number' && this.weightTotal % 1 === 0) ? this.weightTotal.toFixed(0) : this.weightTotal.toFixed(1),
          awbList: list
        }
        createPreDeclaration(params).then(res => {
          if (res.data.CODE === 200) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `Pre-declaration ${res.data.DATA} create successfully!`,
              type: 'success',
              showClose: true,
              duration: 3000,
              offset: 120
            })
            this.$router.push({ query: { type: 'View', preDeclarationId: res.data.DATA } })
            this.title = 'View'
            this.disabled = true
            this.listInfo.infoData = list
            this.listInfo.infoData.forEach(element => {
              this.$set(element, 'destDisabled', true)
            })
          } else {
            if (res.data.MESSAGE) {
              this.$message({
                dangerouslyUseHTMLString: true,
                message: `${res.data.MESSAGE}`,
                type: 'error',
                showClose: true,
                duration: 8000,
                offset: 120
              })
            }
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    IsNULLInfoData (datas) {
      let awblist = []
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i]
        if ((i !== 0) && (data.awbNo === null || data.awbNo === '') && (data.dest === null || data.dest === '') && (data.pieces === null || data.pieces === '') && (data.weight === null || data.weight === '')) continue
        if (data.awbNo === null || data.awbNo === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'AWB# cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return []
        }
        if (data.dest === null || data.dest === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Destination cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return []
        }
        if (data.pieces === null || data.pieces === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Pieces cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return []
        }
        if (data.weight === null || data.weight === '') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: 'Weight(KG) cannot be empty!',
            type: 'error',
            showClose: true,
            duration: 3000,
            offset: 120
          })
          return []
        }
        awblist.push({
          awbNo: data.awbNo,
          dest: data.dest,
          pieces: data.pieces,
          weight: data.weight
        })
      }
      return awblist
    },
    ISNullList (datas) {
      let awblist = []
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i]
        if ((data.awbNo === null || data.awbNo === '') && (data.dest === null || data.dest === '') && (data.pieces === null || data.pieces === '') && (data.weight === null || data.weight === '')) break
        awblist.push(data)
      }
      return awblist
    },
    cancel () {
      this.$router.push({
        path: '/declaration/declaration-list'
      })
    },
    piecesCheck (val, index) {
      this.listInfo.infoData[index].pieces = this.listInfo.infoData[index].pieces.replace(/[^\d]/g, '')
    },
    weightCheck (val, index) {
      var pattern = /^(0|-?[1-9][0-9]*)(\.[\d]?)?$/
      if (pattern.test(this.listInfo.infoData[index].weight) === false) {
        this.listInfo.infoData[index].weight = null
      }
    },
    remoteMethodDestination (query) {
      if (query !== '') {
        this.destinationLoading = true
        const obj = {
          officeCode: getOfficeCode(),
          destination: query.toUpperCase()
        }
        queryDeclaration(obj).then(res => {
          this.destinationOptions = []
          const resulst = res.data.DATA
          if (resulst !== null) {
            resulst.forEach((element, index) => {
              this.destinationOptions.push({
                value: element.destination,
                label: element.destination,
                id: index
              })
            })
          }
          this.destinationLoading = false
        })
      } else {
        this.destinationOptions = []
      }
    },
    remoteMethodListDestination (query) {
      if (query !== '') {
        this.destinationLoading = true
        const obj = {
          officeCode: getOfficeCode(),
          destination: query.toUpperCase()
        }
        queryDeclaration(obj).then(res => {
          this.destinationOptions = []
          const resulst = res.data.DATA
          if (resulst !== null) {
            resulst.forEach((element, index) => {
              this.destinationOptions.push({
                value: element.destination,
                label: element.destination,
                id: index
              })
            })
          }
          this.destinationLoading = false
        })
      } else this.destinationOptions = []
    },
    remoteMethodTruckerName (query) {
      if (query.length >= 3) {
        this.truckerNameLoading = true
        const obj = {
          data: {
            TruckerName: query.toUpperCase()
          },
          loading: false
        }
        getTruckerName(obj).then(res => {
          this.truckerNameOptions = []
          const resulst = res.data.DATA
          if (resulst) {
            resulst.forEach((element, index) => {
              this.truckerNameOptions.push({
                value: element.TruckerName + ',' + element.HIADNo,
                label: element.TruckerName,
                id: index
              })
            })
          }
          this.truckerNameLoading = false
        })
      } else this.truckerNameOptions = []
    },
    remoteMethodAwb (query) {
      if (query !== '') {
        this.awbLoading = true
        const obj = {
          data: {
            officeCode: getOfficeCode(),
            awbNo: query.toUpperCase()
          },
          loading: false
        }
        getFasaData(obj).then(res => {
          this.awbOptions = []
          const resulst = res.data.DATA.datas
          resulst.forEach((element, index) => {
            this.awbOptions.push({
              value: element.awb_no,
              label: element.awb_no,
              dest: element.dest_airport,
              id: index
            })
          })
          this.awbLoading = false
        }).catch(err => {
          console.log(err)
        })
      } else this.awbOptions = []
    },
    truckerNameChange (val) {
      const str = val.split(',')
      this.basicInfo.hkidNo = str[1]
      this.basicInfo.truckerName = str[0]
    },
    awbChange (index) {
      if (this.disabled === false) {
        if (this.alreadyExistsList.includes(this.listInfo.infoData[index].awbNo)) {
          this.listInfo.infoData[index].awbNo = null
        } else if (this.awbOptions.length > 0) {
          const pattern = /^[0-9]{3}[-][0-9]{8}$/
          if (pattern.test(this.listInfo.infoData[index].awbNo) !== true) {
            this.listInfo.infoData[index].awbNo = null
            this.listInfo.infoData[index].destDisabled = true
            this.listInfo.infoData[index].dest = null
          } else {
            this.listInfo.infoData[index].destDisabled = true
            this.listInfo.infoData[index].dest = this.awbOptions[0].dest
          }
        } else {
          const pattern = /^[0-9]{3}[-][0-9]{8}$/
          if (pattern.test(this.listInfo.infoData[index].awbNo) !== true) {
            this.listInfo.infoData[index].awbNo = null
          } else if (Number(this.listInfo.infoData[index].awbNo.slice(4, 11)) === 0) {
            this.listInfo.infoData[index].awbNo = null
          } else if (Number(this.listInfo.infoData[index].awbNo.slice(4, 11)) % 7 !== Number(this.listInfo.infoData[index].awbNo.slice(-1))) {
            this.listInfo.infoData[index].awbNo = null
          } else if (this.$route.query.type !== 'View') {
            this.listInfo.infoData[index].destDisabled = false
          }
        }
        this.listInfo.infoData.forEach(element => {
          this.alreadyExistsList.push(element.awbNo)
        })
      }
    },
    LimitInput (value, type) {
      switch (type) {
        case 'test':
          return false
        case 'string': {
          var pattern = /[^\W]/
          let message = false
          for (const char of value) {
            if (pattern.test(char) === false) {
              this.basicInfo.flightNo = null
              message = true
            }
          }
          if (message) {
            this.$message.error('Only letters and numbers can be entered')
          }
          return false
        }
        case 'int':
          return false
        case 'float':
          return value.replace(/[^\d.]/g, '')
        case 'uint':
          if (value.replace(/[0-9]+/g, '')) {
            this.basicInfo.numberOfseal = null
          }
          return value.replace(/[^\d]+/g, '') // 输入的负数和字符串被替换为''
        case 'bool':
          return false
        case 'datetime':
          return false
        default:
          break
      }
    },
    selectAwbFocus (e, index) {
      if (e.target.value.length > 0) {
        const value = e.target.value
        const input = this.$refs['searchSelect' + index].$children[0].$refs.input
        input.value = value
      }
    },
    downloadRCL () {
      downloadFileRCL(this.preDeclarationId).then(res => {
        let blob = res.data
        let objectUrl = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = objectUrl
        a.download = res.headers.filename
        a.click()
      }).catch(err => {
        console.log(err)
      })
    }
  },
  watch: {
    'basicInfo.cargoType': {
      handler: function () {
        if (this.basicInfo.cargoType === 'MIX') {
          let num = 8 - this.listInfo.infoData.length
          for (let i = 0; i < num; i++) {
            this.listInfo.infoData.push({
              awbNo: null,
              dest: null,
              pieces: null,
              weight: null,
              destDisabled: true
            })
          }
        } else {
          let list = this.ISNullList(this.listInfo.infoData)
          if (list.length > 0) {
            let retain = []
            for (let i = 0; i < this.listInfo.infoData.length; i++) {
              let data = this.listInfo.infoData[i]
              if (data.awbNo !== null && data.awbNo !== '') {
                retain.push(data)
              } else if (data.dest !== null && data.dest !== '') {
                retain.push(data)
              } else if (data.pieces !== null && data.pieces !== '') {
                retain.push(data)
              } else if (data.weight !== null && data.weight !== '') {
                retain.push(data)
              }
            }
            this.listInfo.infoData = retain
          } else {
            this.listInfo.infoData = [{
              awbNo: null,
              dest: null,
              pieces: null,
              weight: null,
              destDisabled: true
            }]
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  .wrap-header {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
  }
  .wrap-content{
    padding: 0 24px 24px;
    .required {
      color: red;
      margin-right: 4px;
      font-weight: 700;
    }
    .basic_info{
      width: calc(100% - 48px);
      height: auto;
      padding: 24px 24px 0 24px;
      .empty-box{
        height:1px;
      }
      .el-select, .el-input, .el-date-editor--date{
        width: 90%;
      }
      .two-input{
        display: flex;
      }
      .select-input{
        ::v-deep {
          .el-input__suffix-inner {
            display: none !important;
          }
          .el-select-dropdown {
            border: inherit !important;
            display: none !important;
          }
          .el-popper[x-placement^=bottom] .popper__arrow{
            display: none !important;
          }
        }
      }
      .destinationSelect{
        ::v-deep {
          .el-select__caret, .el-select__caret.is-reverse {
            transform: rotateX(360deg);
          }
          .el-icon-arrow-up::before {
            content: '\e778';
          }
          .el-input__icon:after {
            content: '\e778';
            height: 0;
            width: 0;
            display: block;
          }
        }
      }
    }
    .list_info {
      padding: 24px;
      .list_info_table{
        width: 100%;
        border-radius: 8px;
        .el-select{
          width: 90%;
        }
      }
      .add{
        color: #1890ff;
        font-size: 26px;
      }
      .marginTop{
        margin-top: 10px;
      }
      .right{
        text-align: right;
        margin-right: 8px;
      }
      .center{
        text-align: center;
      }
      .title{
        color: #000;
      }
      .awb-style{
        display:flex;
        align-items: center;
      }
      ::v-deep {
        .el-select__caret, .el-select__caret.is-reverse {
          transform: rotateX(360deg);
        }
        .el-icon-arrow-up::before {
          content: '\e778';
        }
        .el-input__icon:after {
          content: '\e778';
          height: 0;
          width: 0;
          display: block;
        }
      }
    }
    .submit-btn{
      text-align: right;
      margin-top: 24px;
    }
    ::v-deep{
      .el-form-item{
        margin-bottom: 10px;
      }
      .el-form-item__label {
        color: #000;
        font-weight: bold;
        padding-right: 20px;
      }
      .el-input__inner{
        text-transform: uppercase;
      }
      .el-select__input{
        text-transform: uppercase;
      }
      .el-select__tags-text{
        text-transform: uppercase;
      }
    }
    #printTemplate{
      width: 710px;
      display: none;
    }
  }
}
</style>
