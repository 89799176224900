import request from '../../utils/request'

export function getFasaData (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/awb/query',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj.data,
    loading: obj.loading
  })
}

export function getFasaDataBymawbNo (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/awb/loadafsa',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj,
    loading: true
  })
}

export function judgeAwbExist (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/awb/judge',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj,
    loading: true
  })
}
